<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" md="12">
          <v-card>
            <div>
            <v-btn small class="ma-2" @click="periodEdit()" fab color="warning"><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
            <v-simple-table>
              <tbody>
                <tr>
                  <td>ครั้งที่</td>
                  <td class="text-center font-weight-bold">
                    {{ periods.period_times }}
                  </td>
                  <td>ปี</td>
                  <td class="text-center font-weight-bold">
                    {{ periods.period_year }}
                  </td>
                </tr>
                <tr>
                  <td>เริ่มวันที่</td>
                  <td class="text-center font-weight-bold">
                    {{ periods.period_start   | moment("add", "543 years")
                      | moment("D MMMM YYYY") }}
                  </td>

                  <td>สิ้นสุดวันที่</td>
                  <td class="text-center font-weight-bold">
                    {{ periods.period_stop   | moment("add", "543 years")
                      | moment("D MMMM YYYY") }}
                  </td>
                </tr>
                <tr>
                  <td>อายุงาน</td>
                  <td class="text-center font-weight-bold">
                    {{ periods.period_ageTime }}
                  </td>

                  <td>วันที่สิ้นสุดการนับ</td>
                  <td class="text-center font-weight-bold">
                    {{ periods.period_cal_end   | moment("add", "543 years")
                      | moment("D MMMM YYYY") }}
                  </td>
                </tr>

                <tr>
                  <td>สถานะระบบ</td>
                  <td class="text-center font-weight-bold">
                <v-chip color="green" dark v-if="periods.period_enable==='1'">เปิด</v-chip>
                <v-chip color="red" dark v-else-if="periods.period_enable==='0'">ปิด</v-chip>
                  </td>

                  <td>สถานะประมวลผล</td>
                  <td class="text-center font-weight-bold">
                    <v-chip color="green" dark v-if="periods.period_enable_process==='1'">เปิด</v-chip>
                <v-chip color="red" dark v-else-if="periods.period_enable_process==='0'">ปิด</v-chip>
              
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card></v-col
        >       
      </v-row>

      <!-- V-model editperioddialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editperioddialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลปี"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editperiodform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-select
                        v-model="editperiod.period_year"
                        :items="periodselect"
                        item-text="text"
                        item-value="value"
                        label="เลือกปีการศึกษา"
                        single-line
                        rounded
                        outlined
                      >
                      </v-select>
                      <v-spacer></v-spacer>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        type="number"
                        label="ครั้งที่"
                        v-model="editperiod.period_times"
                        required
                        :rules="[v => !!v || '']"
                        rounded
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-select
                        :items="period_types"
                        item-value="value"
                        label="ปรเภทการย้าย"
                        v-model="editperiod.period_type"
                        required
                        :rules="[v => !!v || '']"
                        rounded
                        outlined
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 md6 v-if="editperiod.period_type === 'manage'">
                      <v-select
                        :items="period_usermanage_status"
                        item-value="value"
                        label="ตำแหน่ง"
                        v-model="editperiod.period_user_status"
                        required
                        :rules="[v => !!v || '']"
                        rounded
                        outlined
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        type="date"
                        label="วันที่เริ่ม"
                        v-model="editperiod.period_start"
                        required
                        :rules="[v => !!v || '']"
                        rounded
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        type="date"
                        label="วันที่สิ้นสุด"
                        v-model="editperiod.period_stop"
                        required
                        :rules="[v => !!v || '']"
                        rounded
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        type="date"
                        label="วันที่สิ้นสุดการนับถึง"
                        v-model="editperiod.period_cal_end"
                        required
                        :rules="[v => !!v || '']"
                        rounded
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        type="number"
                        label="อายุงานที่ใช้ในการคำนวณ"
                        v-model="editperiod.period_ageTime"
                        required
                        :rules="[v => !!v || '']"
                        rounded
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-switch
                        v-model="editperiod.period_enable_sw"
                        label="เปิดใช้ระบบย้าย"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-switch
                        v-model="editperiod.period_enable_sw_process"
                        label="เปิดใช้ระบบประมวลผล"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="editperioddialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editperiodSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        auto-height
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">
          Close
        </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import VueMoment from "vue-moment";
import moment from "moment-timezone";
export default {
  name: "period",
  data() {
    return {
      VueMoment,
      moment,
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addperioddialog: false,
      editperioddialog: false,
      deleteperioddialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      period_types: [
        { text: "สายงานบริหารสถานศึกษา", value: "manage" },
        { text: "สายการสอนและสายสนับสนุนการสอน", value: "teacher" }
      ],
      period_usermanage_status: [
        { text: "ผู้อำนวยการวิทยาลัย", value: "director" },
        { text: "รองผู้อำนวยการวิทยาลัย", value: "se_director" }
      ],
      periods: [],
      addperiod: {},
      editperiod: {},
      search: "",
      pagination: {},
      headers: [
        { text: "ครั้งที่", align: "center", value: "period_times" },
        { text: "ปี", align: "center", value: "period_year" },
        { text: "เริ่มวันที่", align: "center", value: "period_start" },
        { text: "สิ้นสุดวันที่", align: "center", value: "period_stop" },
        { text: "วันที่นับถึง", align: "center", value: "period_cal_end" },
        { text: "อายุงานคำนวณ", align: "center", value: "period_ageTime" },
        { text: "เกี่ยวข้องกับ", align: "center", value: "period_type" },
        { text: "ตำแหน่ง", align: "center", value: "period_user_status" },
        { text: "สถานะ", align: "center", value: "period_enable" },
        { text: "ประมวลผล", align: "center", value: "period_enable_process" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      periodselect: [
        {
          value: "2021",
          text: "2564"
        },
        {
          value: "2022",
          text: "2565"
        },
        {
          value: "2023",
          text: "2566"
        },
        {
          value: "2024",
          text: "2567"
        },
        {
          value: "2025",
          text: "2568"
        },
        {
          value: "2026",
          text: "2569"
        },
        {
          value: "2027",
          text: "2570"
        }
      ],
      data_syslog: {},
      linemassage: {}
    };
  },
  async mounted() {
    this.periodQueryAll();
  },
  methods: {
    async periodQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("period.php", {
          ApiKey: this.ApiKey,
          period_id: "301"
        })
        .finally(() => (this.loading = false));
      this.periods = result.data;
    },

    async periodEdit() {
      let result = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_id: "301"
      });
      this.editperiod = result.data;

      if (this.editperiod.period_enable == 1)
        this.editperiod.period_enable_sw = true;
      else this.editperiod.period_enable_sw = false;

      if (this.editperiod.period_enable_process == true)
        this.editperiod.period_enable_sw_process = true;
      else this.editperiod.period_enable_sw_process = false;

      this.editperioddialog = true;
    },
    async editperiodSubmit() {
      if (this.editperiod.period_enable_sw == true)
        this.editperiod.period_enable = "1";
      else this.editperiod.period_enable = "0";

      if (this.editperiod.period_enable_sw_process == true)
        this.editperiod.period_enable_process = "1";
      else this.editperiod.period_enable_process = "0";

      this.editperiod.ApiKey = this.ApiKey;
      let result = await this.$http.post("period.update.php", this.editperiod);
      if (result.data.status == true) {
        this.period = result.data;
        Swal.fire({
          icon: "success",
          title: "แก้ไขข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.periodQueryAll();
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "update";
        this.data_syslog.page_log = "period";
        this.data_syslog.table_log = "period";
        this.data_syslog.date_times = this.date_today_log;
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
      } else {
        Swal.fire({
          icon: "warning",
          title: "แก้ไขข้อมูลผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.editperioddialog = false;
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
